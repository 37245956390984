import React from 'react';
import { graphql } from 'gatsby';

import Hero from '../components/Hero';
import Band from '../components/Band/Band';
import Layout from '../components/Layout/Layout';
import { Container } from '../components/Grid';
import Contact from '../components/Contact/Contact';
import Typography from '../components/Typography';
import PageHeader from '../components/PageHeader/PageHeader';
import SEO from '../components/SEO';
import Tiles from '../components/Tiles/Tiles';

export default ({ data }) => (
    <Layout>
        <SEO
            title="Design focused recruitment specialist"
            description="Black Dot is a design focused recruitment specialist connecting innovative and compelling brands with game-changing talent"
        />
        <Hero mobileImage={data.mobileImage} desktopImage={data.desktopImage} logoColor="white" />
        <Band size="md">
            <Container>
                <Typography color="salmon">
                    <PageHeader as="h1">
                        We're passionate about inspiring people to find fulfilling opportunities and enhance their lives
                    </PageHeader>

                    <p>Hello, we are Black Dot.</p>
                    <p>Black Dot is a design focused recruitment specialist – essentially we are a connector.</p>
                    <p>We believe in connecting innovative and compelling brands with game-changing talent.</p>
                </Typography>
            </Container>
        </Band>
        <Band>
            <Tiles />
        </Band>
        <Band size="md">
            <Container>
                <Contact heading="Let's connect" />
            </Container>
        </Band>
    </Layout>
);

export const query = graphql`
    query IndexPageQuery {
        desktopImage: file(relativePath: { eq: "unsplash-B0GDnkMEC0Y.jpg" }) {
            ...HeroBackgroundImage_Desktop
        }
        mobileImage: file(relativePath: { eq: "unsplash-sm-B0GDnkMEC0Y.jpg" }) {
            ...HeroBackgroundImage_Mobile
        }
    }
`;
